import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { getSystemMessages } from '../../services';
import { getActiveSite } from '../../bundles/sites';
import { getProject } from '../../bundles/projects';
import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';
import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
import SiteCreateView from '../SiteCreateView';
import Notifications from '../Notifications';

const BaseViewIoTCloud = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);

  const [headerHeight, setHeaderHeight] = useState(0);
  const systemMessages = useSelector(getSystemMessages);
  const params = useParams();
  const { name: projectName } = params;
  const project = useSelector((state) => getProject(state, projectName));

  const getSystemMessagesHeight = () =>
    document.querySelector('.page-header-component')?.offsetHeight || 0;

  const updateHeaderHeight = () => {
    setHeaderHeight(getSystemMessagesHeight());
  };

  useEffect(() => {
    setHeaderHeight(getSystemMessagesHeight());
  }, [systemMessages, headerHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const showSidebar = true;

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (!project) return null;

  return (
    <>
      {showSidebar && (
        <Sidebar
          site={site}
          headerHeight={headerHeight}
        />
      )}

      <section className={`base-view ${className} ${showSidebar ? '' : 'no-side-margin'}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: project.name,
          }}
          singlePage={true}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          {headerChildren}
          {withConnectivity && !noSiteView && !siteAdminView && (
            <ConnectivityStatus siteId={site.id} />
          )}

          <Notifications />

          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div className="panel">
          <div className="panel-header">
            <span>EC Configuration</span>
            <div className="panel-header-button">
              <SiteCreateView />
            </div>
          </div>

          <div className="mims-config">
            <div className="mims-config-type">
              <p>EC Environment</p>
              <p>EC Tenant ID</p>
              <p>EC Project ID</p>
              <p>EC Edge ID</p>
              <p>EC Device Twin API</p>
              <p>EC Data Access API</p>
              <p>EC Type Def API</p>
            </div>

            <div className="mims-config-value">
              <p>{project?.environment}</p>
              <p>{project?.tenantId}</p>
              <p>{project?.projectId}</p>
              <p>{project?.edgeId}</p>
              <p>{project?.ecDeviceTwinAPI}</p>
              <p>{project?.ecDataAccessAPI}</p>
              <p>{project?.ecTypeDefAPI}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BaseViewIoTCloud;
