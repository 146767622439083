import { createClient } from '@iq/services';
import { getAccessToken } from '@iq/react-components';
import test from '../test.json';

import config from './config';

const services = createClient({
  ...config.services,
  onRequestToken: getAccessToken,
});

export function getServiceToken(type) {
  return services[type].getToken();
}

/** ********************************************
 *                                             *
 *               Auth                          *
 *                                             *
 ******************************************** */

export function getGrants(oid) {
  return services.auth.grants(`users/${oid}`);
}

export function getMemberTypes() {
  return services.auth.getMemberTypes();
}

export function createMember(data) {
  return services.auth.createMember(data);
}

export function updateMember(memberId, data) {
  return services.auth.updateMember(memberId, data);
}

export function deleteMember(memberId) {
  return services.auth.deleteMember(memberId);
}

export function getMembers(data) {
  return services.auth.getMembers(data);
}

export function deleteMemberConnection(memberId, userId) {
  return services.auth.deleteMemberConnection(memberId, userId);
}

export function getInvitations(query) {
  return services.auth.getInvitations(query);
}

export function createInvitation(data) {
  return services.auth.createInvitation(data);
}

export function updateInvitation(invitationId, data) {
  return services.auth.updateInvitation(invitationId, data);
}

export function deleteInvitation(invitationId) {
  return services.auth.deleteInvitation(invitationId);
}

export function authorizeUser(data) {
  return services.auth.authorizeUser(data);
}

export function getOneUser(userId) {
  return services.auth.getOneUser(userId);
}

export function getUsers(query) {
  return services.auth.getUsers(query);
}

export function getLimitedUsers(query) {
  return services.auth.getLimitedUsers(query);
}

export function deleteUser(userId) {
  return services.auth.deleteUser(userId);
}

export function getServiceAccounts() {
  return services.auth.getServiceAccounts();
}

export function transferServiceAccountPermissions(data) {
  return services.auth.transferServiceAccountPermissions(data);
}

export function deleteEntity(entity) {
  return services.auth.deleteEntity(entity);
}

/** ********************************************
 *                                             *
 *               Collaboration                 *
 *                                             *
 ******************************************** */

export function getSystemMessages() {
  return services.collaboration.getSystemMessages();
}

/** ********************************************
 *                                             *
 *               Data                          *
 *                                             *
 ******************************************** */

export function getSources(siteId) {
  return services.data.getSources({ site: siteId });
}

export function importSource(sourceId) {
  return services.data.importSourceComponents(sourceId);
}
export function uploadAOExcel(sourceId, formData) {
  return services.data.importAOExcel(sourceId, formData);
}

export function getSource(sourceId) {
  return services.data.getOneSource(sourceId);
}

export function updateSource(sourceId, data) {
  return services.data.updateSource(sourceId, data);
}

export function deleteSource(sourceId) {
  return services.data.deleteSource(sourceId);
}

export function createSource(data) {
  return services.data.createSource(data);
}

export function getSourceTypes() {
  return services.data.getSourceTypes();
}

export function getSourceHealth(siteId, org) {
  return services.data.getSourceHealth({ site: siteId, org });
}

export function getComponents(siteId) {
  return services.data.getComponents({ site: siteId });
}

export function getSiteComponentTree(siteId) {
  return services.data.getSiteComponentTree({ site: siteId });
}

export function getComponentsStatus(siteId) {
  return services.data.getComponentsStatus({ site: siteId });
}
export function createComponent(data) {
  return services.data.createComponent(data);
}

export function createVariable(sourceId, data) {
  return services.data.createVariable(sourceId, data);
}

export function updateVariable(sourceId, variableId, data) {
  return services.data.updateVariable(sourceId, variableId, data);
}

export function deleteVariable(sourceId, variableId) {
  return services.data.deleteVariable(sourceId, variableId);
}

export function updateComponent(componentId, data) {
  return services.data.updateComponent(componentId, data);
}

export function deleteComponent(componentId) {
  return services.data.deleteComponent(componentId);
}

export function deleteNonSiteComponents(query) {
  return services.data.deleteNonSiteComponents(query);
}

export function getSeries(data) {
  return services.data.getSeries(data);
}

export function getLastValues(data) {
  return services.data.getLastValues(data);
}

export function uploadEventsFile(formData, sourceId) {
  return services.data.injectEvents(formData, sourceId);
}

export function getTfrTemplates(query) {
  return services.data.getTfrTemplates(query);
}

export function createTfrTemplate(data) {
  return services.data.createTfrTemplate(data);
}

export function updateTfrTemplate(templateId, data) {
  return services.data.updateTfrTemplate(templateId, data);
}

export function deleteTfrTemplate(templateId) {
  return services.data.deleteTfrTemplate(templateId);
}

export function createOrder(formData) {
  return services.data.createOrder(formData);
}

export function getEvents(query) {
  return services.data.getEvents(query);
}

export function getVariables(sourceId, query) {
  return services.data.getVariables(sourceId, query);
}

export function getEventBuckets(query) {
  return services.data.getEventBuckets(query);
}

export function getVariable(sourceId, variableId) {
  return services.data.getVariable(sourceId, variableId);
}

export function getVisualizations(siteId) {
  return services.data.getVisualizations({ site: siteId });
}

export function getVisualization(visualizationId) {
  return services.data.getVisualization(visualizationId);
}

export function createVisualization(data) {
  return services.data.createVisualization(data);
}

export function bulkUpdateVisualization(data) {
  return services.data.bulkUpdateVisualization(data);
}

export function updateVisualization(visualizationId, data) {
  return services.data.updateVisualization(visualizationId, data);
}

export function deleteVisualization(visualizationId) {
  return services.data.deleteVisualization(visualizationId);
}

export function uploadComponentsExcel(formData) {
  return services.data.importComponents(formData);
}

export function downloadComponentsExcelTemplate(query) {
  return services.data.exportComponents(query);
}

export function uploadVariablesExcel(formData) {
  return services.data.importVariables(formData);
}

export function downloadVariablesExcelTemplate(query) {
  return services.data.exportVariables(query);
}

export function createStateSet(data, site, org) {
  return services.data.createStateSet(data, site, org);
}

export function updateStateSet(stateSetId, data) {
  return services.data.updateStateSet(stateSetId, data);
}

export function getStateSets(query) {
  return services.data.getStateSets(query);
}

export function getStateSet(stateSet) {
  return services.data.getStateSet(stateSet);
}

export function deleteStateSet(stateSet) {
  return services.data.deleteStateSet(stateSet);
}

export function checkConnectivity(sourceId) {
  return services.data.checkConnectivity(sourceId);
}

/** ********************************************
 *                                             *
 *               Event                         *
 *                                             *
 ******************************************** */

export function getEventTypes(siteId) {
  return services.event.getEventTypes(siteId);
}

export function getUserEvents(query) {
  return services.event.getEvents(query);
}

/** ********************************************
 *                                             *
 *               File                          *
 *                                             *
 ******************************************** */

export function getFiles(query) {
  return services.file.getFiles(query);
}

export function getInstructionals(query) {
  return services.file.getInstructionals(query);
}

export function deleteFile(fileId) {
  return services.file.deleteFile(fileId);
}

export function getZippedFiles(filesQuery) {
  return services.file.getZippedFiles(filesQuery);
}

export function getBookmark(id) {
  return services.file.getBookmark(id);
}

export function getBookmarks(query) {
  return services.file.getBookmarks(query);
}

export function createBookmark(fileId, body) {
  return services.file.createBookmark(fileId, body);
}

export function deleteBookmark(fileId, body) {
  return services.file.deleteBookmark(fileId, body);
}

export function updateBookmark(fileId, bookmarkId, body) {
  return services.file.updateBookmark(fileId, bookmarkId, body);
}

export function uploadFile(formData) {
  return services.file.createFile(formData);
}

export function updateFile(fileId, body) {
  return services.file.updateFile(fileId, body);
}

export function getTypes(query) {
  return services.file.getTypes(query);
}

/** ********************************************
 *                                             *
 *               Integration                   *
 *                                             *
 ******************************************** */

export function getRemoteAssetInfo(integrationId, componentId) {
  return services.integration.getRemoteAssetInfo(integrationId, componentId);
}

export function createRemoteEvent(integrationId, data) {
  return services.integration.createRemoteEvent(integrationId, data);
}

export function getIntegrations(query) {
  return services.integration.getIntegrations(query);
}

export function getIntegrationTypes() {
  return services.integration.getIntegrationTypes();
}

export function createIntegration(data) {
  return services.integration.createIntegration(data);
}

export function createIntegrationTemplate(data) {
  return services.integration.createIntegrationTemplate(data);
}

export function updateIntegration(integrationId, data) {
  return services.integration.updateIntegration(integrationId, data);
}

export function updateIntegrationTemplate(integrationId, data) {
  return services.integration.updateIntegrationTemplate(integrationId, data);
}

export function deleteIntegration(integrationId) {
  return services.integration.deleteIntegration(integrationId);
}

export function syncRemoteAssets(integrationId, data) {
  return services.integration.syncRemoteAssets(integrationId, data);
}

export function createIntegratedSource(integrationId, data) {
  return services.integration.createSource(integrationId, data);
}

export function updateIntegratedSource(integrationId, sourceId, data) {
  return services.integration.updateSource(integrationId, sourceId, data);
}

export function getIntegratedSources(integrationId, query) {
  return services.integration.getSources(integrationId, query);
}

/** ********************************************
 *                                             *
 *               Job                           *
 *                                             *
 ******************************************** */

export function getAlgorithms(query) {
  return services.job.getAlgorithms(query);
}

export function createAlgorithm(body) {
  return services.job.createAlgorithm(body);
}

export function deleteAlgorithm(algorithmId) {
  return services.job.deleteAlgorithm(algorithmId);
}

export function updateAlgorithm(algorithmId, data) {
  return services.job.updateAlgorithm(algorithmId, data);
}

export function getTasks(query) {
  return services.job.getTasks(query);
}

export function createTask(body) {
  return services.job.createTask(body);
}

export function deleteTask(algorithmId) {
  return services.job.deleteTask(algorithmId);
}

export function updateTask(algorithmId, data) {
  return services.job.updateTask(algorithmId, data);
}

export function getJobs(taskId) {
  return services.job.getJobs(taskId);
}

/** ********************************************
 *                                             *
 *               Model                         *
 *                                             *
 ******************************************** */

export function getModel(modelId) {
  return services.model.getModel(modelId);
}

export function getModels(siteId) {
  return services.model.getModels({ site: siteId });
}

export function createModel(body) {
  return services.model.createModel(body);
}

export function updateModel(modelId, body) {
  return services.model.updateModel(modelId, body);
}

export function createModelVersion(modelId, body) {
  return services.model.createVersion(modelId, body);
}

export function deleteModel(modelId) {
  return services.model.deleteModel(modelId);
}

export function getSiteMappings(siteId) {
  return services.model.getSiteMappings(siteId);
}

export function getVersionMappings(modelId, versionId) {
  return services.model.getMappings(modelId, versionId);
}

export function createMapping(modelId, versionId, mapping) {
  return services.model.createMapping(modelId, versionId, mapping);
}

export function updateOrDeleteMappings(modelId, versionId, scope) {
  return services.model.deleteMappings(modelId, versionId, scope);
}

export function updateMapping(modelId, versionId, mappingId, mapping) {
  return services.model.updateMapping(modelId, versionId, mappingId, mapping);
}

export function deleteMapping(modelId, versionId, mappingId) {
  return services.model.deleteMapping(modelId, versionId, mappingId);
}

export function updateSceneConfig(modelId, versionId, sceneConfig) {
  return services.model.updateSceneConfig(modelId, versionId, sceneConfig);
}

export async function getModelThumbnail(modelId, versionId) {
  return services.model.getThumbnail(modelId, versionId);
}

export function getGeometries(modelId) {
  return services.model.getGeometries(modelId);
}

export function createOrUpdateGeometries(modelId, data) {
  return services.model.createOrUpdateGeometries(modelId, data);
}
export function deleteGeometry(modelId, geometryId) {
  return services.model.deleteGeometry(modelId, geometryId);
}

/** ********************************************
 *                                             *
 *               Site                          *
 *                                             *
 ******************************************** */

export async function getAllSitesData() {
  return services.site.getSites();
}

export function createSite(body) {
  return services.site.createSite(body);
}

export async function getAllProjectData() {
  return test;
}

export function updateSite(siteId, site) {
  return services.site.updateSite(siteId, site);
}

export function deleteSite(siteId) {
  return services.site.deleteSite(siteId);
}

export function addLayout(siteId, layout) {
  return services.site.addLayout(siteId, layout);
}

export function updateLayout(siteId, layoutId, layout) {
  return services.site.updateLayout(siteId, layoutId, layout);
}

export function lookupSite(type, thirdPartyId, query) {
  return services.site.lookupSite(type, thirdPartyId, query);
}

export function uploadSitesExcel(file) {
  return services.site.uploadSitesExcel(file);
}

export function downloadSitesExcelTemplate() {
  return services.site.downloadSitesExcelTemplate();
}

/** ********************************************
 *                                             *
 *               Project                       *
 *                                             *
 ******************************************** */

export function getAllProjects() {
  return services.backoffice.getProjects();
}

export function getProject(id) {
  return services.backoffice.getOneProject(id);
}

export function createProject(data) {
  return services.backoffice.createProject(data);
}

export function updateProject(id, data) {
  return services.backoffice.updateProject(id, data);
}

export function deleteProject(id) {
  return services.backoffice.deleteProject(id);
}

export function exportMimsProject(id) {
  services.backoffice.exportMimsData(id);
}

export function exportEcProject(id) {
  services.backoffice.exportEcData(id);
}

export default services;
