import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { Icon } from '@iq/react-components';

import { getProject } from '../../bundles/projects';

import AppContext from '../../AppContext';
import UploadStatus from '../UploadStatus';
import FileUploadStatus from '../FileUploadStatus';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { theme, isTouchDevice } = useContext(AppContext);
  const [canSidebarExpand, setCanSidebarExpand] = useState(true);
  const expandSideBar = (expand) => (isTouchDevice ? setCanSidebarExpand(expand) : null);

  const [activeNavLink, setActiveNavLink] = useState(null);

  const params = useParams();
  const { name: projectName } = params;
  const project = useSelector((state) => getProject(state, projectName));

  const handleNavClick = (path) => {
    setActiveNavLink(path);
  };

  useEffect(() => {
    const page = pathname.split('/')[1];
    setActiveNavLink(`/${page}`);
  }, []);

  return (
    <>
      <section
        onMouseOver={() => expandSideBar(true)}
        className={`sidebar-component theme-${theme} ${canSidebarExpand ? '' : 'collapse'}`}
      >
        <nav className="main-nav">
          <ul className="pages">
            <li>
              <NavLink
                title="mims"
                exact
                to={{
                  pathname: `/mach/${project.name}/`,
                }}
                isActive={() => activeNavLink === '/mach'}
                onClick={() => handleNavClick('/mach')}
              >
                {/* <Icon
                  icon="check-circle"
                  color={
                    activeNavLink === '/test4' || activeNavLink === '/test3' ? 'green' : 'grey'
                  }
                /> */}
                <span
                  style={{
                    color: activeNavLink === '/mach' ? 'blue' : 'grey',
                  }}
                >
                  MACH Information<br></br>Management System
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                title="iot-cloud"
                exact
                to={{
                  pathname: `/iot-cloud/${project.name}/`,
                }}
                isActive={() => activeNavLink === '/iot-cloud'}
                onClick={() => handleNavClick('/iot-cloud')}
                className={activeNavLink === 'iot-cloud' ? 'active-link' : ''}
              >
                {/* <Icon
                  icon="check-circle"
                  color={activeNavLink === '/test3' ? 'green' : 'grey'}
                /> */}
                <span
                  style={{
                    color: activeNavLink === '/iot-cloud' ? 'blue' : 'grey',
                  }}
                >
                  IoT Cloud
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                title="overview"
                exact
                to={{
                  pathname: `/overview/${project.name}/`,
                }}
                isActive={() => activeNavLink === '/overview'}
                onClick={() => handleNavClick('/overview')}
              >
                {/* <Icon
                  icon="check-circle"
                  color={activeNavLink === '/test3' ? 'green' : 'grey'}
                /> */}
                <span
                  style={{
                    color: activeNavLink === '/overview' ? 'blue' : 'grey',
                  }}
                >
                  Overview
                </span>
              </NavLink>
            </li>
          </ul>
          {/* <StepNavigator /> */}
        </nav>
      </section>
      <UploadStatus />
      <FileUploadStatus />
    </>
  );
};

export default Sidebar;
