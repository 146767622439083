import React, { useEffect, useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Can } from '@iq/react-components';

import SiteFilter from '../SiteFilter';
import SiteCreateView from '../SiteCreateView';
import SystemMessages from '../SystemMessages';
import CustomSelect from '../CustomSelect';
import UserInfo from '../UserInfo';

const PageHeader = ({
  realm,
  title,
  isTenantAdminView,
  children,
  sitesList = false,
  setFilter = () => '',
}) => {
  const headerRef = useRef();

  useEffect(() => {
    window.onscroll = () => {
      if (headerRef.current) {
        if (window.pageYOffset === 0 && headerRef.current.classList.contains('scroll')) {
          headerRef.current.classList.remove('scroll');
        } else if (window.pageYOffset > 0 && !headerRef.current.classList.contains('scroll')) {
          headerRef.current.classList.add('scroll');
        }
      }
    };
  }, []);

  const pageTitle = useMemo(() => {
    if (!title) {
      return null;
    }
    if (title.type === 'string') {
      return (
        <div className="page-header-left">
          <NavLink
            title={title}
            exact
            to={{
              pathname: `/projects/`,
            }}
          >
            <Icon
              icon="back"
              size="l"
            />
          </NavLink>
          <span>{title.value}</span>
        </div>
      );
    }
    if (title.type === 'image') {
      return (
        <img
          className="page-title"
          src={title.value}
        />
      );
    }
    return null;
  }, [title]);

  const sitesListClass = sitesList ? ' sites-list' : '';
  const tenantAdmin = isTenantAdminView ? 'tenant-admin' : '';

  return (
    <div
      ref={headerRef}
      className={`page-header-component${sitesListClass} ${tenantAdmin}`}
    >
      <SystemMessages />
      <div className="page-header-content-wrapper">
        {pageTitle}

        {sitesList && (
          <div className="site-list-tools">
            <SiteFilter onChange={setFilter} />
            <Can permission="sites/Create">
              <SiteCreateView realm={realm} />
            </Can>
          </div>
        )}

        {!sitesList && (
          <div className="page-header-center">
            <CustomSelect
              isMulti={false}
              defaultValue={'MIMS + Energy Connect'}
              rawOptions={[
                {
                  value: 'MIMS + Energy Connect',
                  name: 'MIMS + Energy Connect',
                },
              ]}
            />
          </div>
        )}

        <div className="tools">
          <UserInfo />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
