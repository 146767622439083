/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HvProvider } from '@hitachivantara/uikit-react-core';
import { Router as ReactRouter, Route, Switch, Redirect } from 'react-router-dom';
import { AuthorizationProvider, useAuth } from '@iq/react-components';

import {
  requestGrants,
  getGrants,
  authorizeUser,
  hasCheckedInvitations,
  getIsTenantOrSuperAdmin,
  getHasPermission,
} from '../../bundles/auth';
import { getActiveSite } from '../../bundles/sites';
import { requestProjects } from '../../bundles/projects';
import { requestInstructionals } from '../../bundles/files';
import { getOnline } from '../../bundles/notifications';

import BaseView from '../BaseView';
import BaseViewMach from '../BaseViewMach';
import BaseViewOverview from '../BaseViewOverview';
import BaseViewIoTCloud from '../BaseViewIoTCloud';

import SiteType from '../SiteType';

import NoGrantsView from '../NoGrantsView';
import SourceConnectivityNotification from '../Notifications/SourceConnectivityNotification';

import GlobalMessagePortal from '../Notifications/GlobalMessagePortal';
import TopNotification from '../Notifications/TopNotification';
import AppContext from '../../AppContext';

const { AuthProvider } = AuthorizationProvider;

const NotFoundPage = () => (
  <div className="not-found">
    <h2>Sorry, page not found...</h2>
  </div>
);

const Router = ({ history }) => {
  const dispatch = useDispatch();

  const { subject, realm, profile } = useAuth();

  const { email } = profile;
  /* eslint-disable no-unused-vars */
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [isLoadingGrants, setIsLoadingGrants] = useState(true);

  const grants = useSelector(getGrants);
  const site = useSelector(getActiveSite);

  const online = useSelector(getOnline);
  const invitesChecked = useSelector(hasCheckedInvitations);

  const validSite = site?.org && site.id;
  const isLumadaSite = validSite && site.uiMode === 'Lumada';

  const { setTheme, theme } = useContext(AppContext);

  const setAvtTheme = useCallback(() => {
    setTheme(theme);
  }, [theme]);

  const hvTheme = useMemo(() => (theme === 'dark' ? 'wicked' : null), [theme]);

  const removeFirstLoader = () => {
    const loader = document.getElementById('first-loader');
    if (loader) loader.remove();
  };

  useEffect(() => {
    if (online && isLoadingGrants) {
      dispatch(requestGrants(subject, () => setIsLoadingGrants(false)));
    }
  }, [online, isLoadingGrants]);

  useEffect(() => {
    if (online && invitesChecked) {
      setIsLoadingGrants(true);
    }
  }, [online, invitesChecked]);

  const isTenantAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, realm));
  const canReadSites = useSelector((state) => getHasPermission(state, 'sites/Read'));

  useEffect(() => {
    if (online && canReadSites) {
      dispatch(requestProjects());
      dispatch(requestInstructionals());
    }
  }, [online, canReadSites]);

  useEffect(() => {
    dispatch(
      authorizeUser({
        email,
        id: subject,
        org: realm,
      })
    );
  }, []);

  if (isLoadingGrants || (canReadSites && isLoadingSites)) {
    return (
      <div>
        {!online && (
          <GlobalMessagePortal>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
      </div>
    );
  }

  if (history.location?.pathname !== '/test/') {
    removeFirstLoader();
  }

  if (invitesChecked && !isLoadingGrants && (!grants || !canReadSites)) {
    removeFirstLoader();
    return <NoGrantsView />;
  }

  return (
    <HvProvider
      uiKitTheme={hvTheme}
      changeTheme={setAvtTheme}
    >
      <div className={isLoadingGrants || isLoadingSites ? 'loading-main-content' : 'main-content'}>
        <ReactRouter history={history}>
          <AuthProvider value={{ grants }}>
            <Switch>
              {/* Pre-site routes */}
              <Route
                exact
                path="/"
              >
                <Redirect to="/projects/" />
              </Route>
              <Route
                exact
                path="/projects/"
                render={(props) => (
                  <SiteType
                    {...props}
                    sitesReady={!isLoadingSites}
                    realm={realm}
                    noSiteView
                  />
                )}
              />
              <Route
                path="/tenant-administration/users"
                render={(props) =>
                  isTenantAdmin ? (
                    <BaseView
                      title={{
                        type: 'string',
                        value: 'Tenant Administration - Users',
                      }}
                      noSitesView
                      className="manage-view"
                      isTenantAdminView
                      {...props}
                    ></BaseView>
                  ) : (
                    <Redirect to="/projects/" />
                  )
                }
              />

              {/* NEW TEST SITE AFTER CREATING NEW PROJECT */}

              <Route
                path="/mach/:name"
                render={() => (
                  <BaseViewMach
                    site={site}
                    withSidebar={true}
                    className="site-detail-view-component"
                    withPadding={false}
                  />
                )}
              />

              <Route
                path="/overview/:name"
                render={() => (
                  <BaseViewOverview
                    site={site}
                    withSidebar={true}
                    withRefresh
                    title={
                      isLumadaSite && {
                        type: 'image',
                      }
                    }
                    className="site-detail-view-component"
                    withPadding={false}
                  />
                )}
              />

              <Route
                path="/iot-cloud/:name"
                render={() => (
                  <BaseViewIoTCloud
                    site={site}
                    withSidebar={true}
                    className="site-detail-view-component"
                    withPadding={false}
                  >
                    {/* <SiteDetailView {...props} subject={subject} /> */}
                  </BaseViewIoTCloud>
                )}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </AuthProvider>
        </ReactRouter>
        {!online && (
          <GlobalMessagePortal site={site}>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
        <GlobalMessagePortal>{site.id && <SourceConnectivityNotification />}</GlobalMessagePortal>
      </div>
    </HvProvider>
  );
};

export default Router;
