import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { Button } from '@iq/react-components';

import Loader from '../Loader';

import SitesImportModal from './components/SitesImportModal';
import { lookupSite } from '../../services';
import { getProjects, updateProject } from '../../bundles/projects';

const SiteCreateView = React.memo(({ siteParams = {} }) => {
  const history = useHistory();
  const { integrationInfo = 'Identiq', thirdPartyId } = siteParams;
  const { isIntegration = false, name: integrationName = '' } = integrationInfo;

  const [displayImportSitesModal, setDisplayImportSitesModal] = useState(false);
  const [checkingExistingSite, setCheckingExistingSite] = useState(true);

  const dispatch = useDispatch();

  const { name } = useParams();
  const projects = useSelector(getProjects);
  const project = projects.find((p) => p.name === name);

  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      switch (error.name) {
        case 'format':
          if (error.property === '.org')
            error.message = 'Organization should only contain alpha-numeric characters (no spaces)';
          break;
        case 'maximum':
        case 'minimum':
          if (error.property === '.lat') error.message = 'Enter valid Latitude';
          else if (error.property === '.lng') error.message = 'Enter valid Longitude';
          break;
        case 'required':
          error.message = `Enter ${error.property.charAt(1).toUpperCase() + error.property.slice(2)}`;
          break;
        default:
      }
      /* eslint-enable no-return-assign, no-param-reassign */
      return error;
    });

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUpdateProject = async (data) => {
    const file = data;
    const base64 = await convertBase64(file);
    const fileData = base64.split('base64,')[1];

    const projectData = {
      ...data,
      name: project.name,
      id: project.id,
      ip: project.mimsIP,
      sampleRate: project.sampleRate,
      subscriptionType: project.subscriptionType,
      filePath: project.filePath,
      excelFilePath: project.excelFilePath,
      moduleId: project.decModuleId,
      environment: project.decEnvironment,
      tenantId: project.decTenantId,
      edgeId: project.decEdgeId,
      projectId: project.decProjectId,
      decDeviceTwinAPI: project.decDeviceTwinAPI,
      decDataAccessAPI: project.decDataAccessAPI,
      decTypeDefAPI: project.decTypeDefAPI,
      hasEdge: project.hasEdge,
      status: 0,
      fileData,
    };
    console.log(projectData);
    dispatch(updateProject(projectData));
    setDisplayImportSitesModal(false);
  };

  async function redirectIfSiteExists() {
    const type = (integrationName || '')?.toLowerCase();

    if (thirdPartyId && type) {
      const relative = true;
      const data = await lookupSite(type, thirdPartyId, {
        relative,
      });

      if (data?.exists) {
        history.push(data.url);
      }
    }

    setCheckingExistingSite(false);
  }

  useEffect(() => {
    redirectIfSiteExists();
  }, []);

  return (
    <div className="site-create-view-component">
      {checkingExistingSite ? (
        <Loader text="Checking for existing config ..." />
      ) : (
        <>
          {!isIntegration && (
            <Button
              onClick={() => setDisplayImportSitesModal(true)}
              className="site-item-menu"
            >
              Upload new config
            </Button>
          )}
          {displayImportSitesModal && (
            <SitesImportModal
              onSubmit={handleUpdateProject}
              transformErrors={transformErrors}
              onCloseModal={() => setDisplayImportSitesModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
});

export default SiteCreateView;
