import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { setActiveComponentId } from '../../bundles/application';

import { getSystemMessages } from '../../bundles/systemMessages';
import { getProject } from '../../bundles/projects';
import { getActiveSite } from '../../bundles/sites';

import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';

import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
// import ComponentView from '../SiteAdminView/ComponentView/ComponentView';
import SiteCreateView from '../SiteCreateView';
import Notifications from '../Notifications';

const BaseViewMach = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);

  const [headerHeight, setHeaderHeight] = useState(0);
  const systemMessages = useSelector(getSystemMessages);

  const params = useParams();
  const { name: projectName } = params;
  const project = useSelector((state) => getProject(state, projectName));
  console.log(project);

  const getSystemMessagesHeight = () =>
    document.querySelector('.page-header-component')?.offsetHeight || 0;

  const updateHeaderHeight = () => {
    setHeaderHeight(getSystemMessagesHeight());
  };

  useEffect(() => {
    setHeaderHeight(getSystemMessagesHeight());
  }, [systemMessages, headerHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const showSidebar = true;

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (project) {
    return (
      <>
        {showSidebar && (
          <Sidebar
            site={site}
            headerHeight={headerHeight}
          />
        )}

        <section className={`base-view ${className} ${showSidebar ? '' : 'no-side-margin'}`}>
          <PageHeader
            site={!isTenantAdminView && site}
            title={{
              type: 'string',
              value: project.name,
            }}
            singlePage={true}
            isTenantAdminView={isTenantAdminView}
            dispatchResetComponent={onResetComponent}
          >
            {headerChildren}
            {withConnectivity && !noSiteView && !siteAdminView && (
              <ConnectivityStatus siteId={site.id} />
            )}
            <Notifications />

            <HeaderSettings
              site={site}
              noSiteView={noSiteView}
            />
          </PageHeader>

          <div className="panel">
            <div className="panel-header">
              <span>MIMS Configuration</span>
              <div className="panel-header-button">
                <SiteCreateView />
              </div>
            </div>

            <div className="mims-config">
              <div className="mims-config-type">
                <p>Id</p>
                <p>Name</p>
                <p>MIMS IP</p>
                <p>Subscription Type</p>
                <p>Sample Rate</p>
                <p>MIMS Module Id</p>
              </div>

              <div className="mims-config-value">
                <p>{project.id}</p>
                <p>{project.name}</p>
                <p>{project.ip}</p>
                <p>{project.subscriptionType}</p>
                <p>{project.sampleRate}</p>
                <p>{project.moduleId}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return null;
};

export default BaseViewMach;
