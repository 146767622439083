const getSiteSchema = () => {
  const schema = {
    type: 'object',
    required: ['name', 'deliveryType', 'ip', 'subscriptionType', 'moduleId'],
    properties: {
      name: {
        type: 'string',
        title: 'Project Name',
        minLength: 5,
        placeholder: 'Name of the Project',
      },
      deliveryType: {
        default: 'MIMS + Energy Connect',
        type: 'string',
        title: 'Delivery Type',
        enum: ['MIMS + Energy Connect'],
      },
      ip: {
        type: 'string',
        format: 'ipv4',
        title: 'MIMS IP',
        placeholder: '123.456.7.89',
      },
      subscriptionType: {
        default: 'OnChange',
        type: 'string',
        title: 'Subscription Type',
        enum: ['OnChange', 'SampleInterval'],
      },
      sampleRate: {
        type: 'number',
        title: 'Sample Rate (ms)',
        placeholder: 'Must be between 1000 and 65534 ms',
        minimum: 1000,
        maximum: 65534,
      },
      moduleId: {
        type: 'string',
        title: 'MIMS Module ID',
        placeholder: '00000000-0000-0000-0000-000000000000',
        format: 'uuid',
      },
    },

    if: {
      properties: {
        deliveryType: {
          const: 'MIMS + Energy Connect',
        },
      },
    },
    then: {
      required: [
        'environment',
        'tenantId',
        'edgeId',
        'projectId',
        'ecTypeDefAPI',
        'ecDataAccessAPI',
        'ecDeviceTwinAPI',
      ],
      properties: {
        environment: {
          type: 'string',
          title: 'EC Environment',
          placeholder: 'Name of the Environment',
        },
        tenantId: {
          type: 'string',
          title: 'Tenant ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
        },
        edgeId: {
          type: 'string',
          title: 'Edge ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
        },
        projectId: {
          type: 'string',
          title: 'Project ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
        },
        ecTypeDefAPI: {
          type: 'string',
          title: 'EC Type Defenition API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
        },
        ecDataAccessAPI: {
          type: 'string',
          title: 'EC Data Access API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
        },
        ecDeviceTwinAPI: {
          type: 'string',
          title: 'EC Device Twin API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
        },
      },
    },
  };

  return schema;
};

const siteUISchema = { 'ui:options': { orderable: false } };

export { getSiteSchema, siteUISchema };
